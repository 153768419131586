//
// custom html tags use the default style sheet with each browser. usually display:inline and have zero height.
//
app-root {
	display: block;
	min-height: 100vh;
}

app-header, app-footer {
	display: block;
}

page-check-email, page-reset-password, page-sign-in {
	display: block;
	min-height: 100vh;
}

ui-toggle-box {
	display: inline-block;
}
