@import './variables';
//
// TODO: remove all color-like styles and broad "html" or "body" -like styles. those are all app-specific.
// this should only be
//
html {
	background: #fdfeff;
	color: #535f6b;
	letter-spacing: -0.5px;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

body {
	background: none;
	// color: @colorGrey;
	font-family: @baseFonts;
	font-weight: 300;
}

.action-link {
	color: #007EFA;
	cursor: pointer;
}

.clickable {
	cursor: pointer;
	transition: transform 0.1s cubic-bezier( 0.36, 0, 0.66, -0.56 );

	&:active {
		transform: scale( 0.8 );
	}
}

.aspect-ratio { // various elements use this to force the box-layout to be a square.
	box-sizing: content-box;
	display: inline-block;
	padding: 0px 0px 100% 0px; // you can change the aspect ratio of the box, by changing the bottom padding to be calc( 100% * (9/16) ); // 16:9 ratio
	position: relative; // just be sure to change it in your component or page styles, not here...
	z-index: 1;
}

.clear {
	clear: both;
}

.clear-fix::after {
	clear: both;
	content: "";
	display: table;
}

.full-width {
	width: 100%;
}

.none {
	display: none !important;
}

.align-left {
	text-align: left;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.bold {
	font-weight: bold;
}
