@import './variables';
//
@keyframes tick-box-bounce { // check-box animation
	from, 20%, 40%, 60%, 80%, to {
		-webkit-animation-timing-function: @ani-fn-bounce;
		animation-timing-function: @ani-fn-bounce;
	}

	0% {
		opacity: 0;
		-webkit-transform: scale3d( 0.3, 0.3, 0.3 );
		transform: scale3d( 0.3, 0.3, 0.3 );
	}

	20% {
		-webkit-transform: scale3d( 1.1, 1.1, 1.1 );
		transform: scale3d( 1.1, 1.1, 1.1 );
	}

	40% {
		-webkit-transform: scale3d( 0.9, 0.9, 0.9 );
		transform: scale3d( 0.9, 0.9, 0.9 );
	}

	60% {
		opacity: 1;
		-webkit-transform: scale3d( 1.03, 1.03, 1.03 );
		transform: scale3d( 1.03, 1.03, 1.03 );
	}

	80% {
		-webkit-transform: scale3d( 0.97, 0.97, 0.97 );
		transform: scale3d( 0.97, 0.97, 0.97 );
	}

	to {
		opacity: 1;
		-webkit-transform: scale3d( 1, 1, 1 );
		transform: scale3d( 1, 1, 1 );
	}
}

@keyframes ani-fn-spin {
	from {
		transform:rotate( 0deg );
	}

	to {
		transform:rotate( 360deg );
	}
}
