@import '../variables';
//
.body-heading {
	height: 60px;
	font-size: (@grid-unit-size / 3);
	font-weight: 400;
	line-height: @grid-unit-size;
	padding: 0px 0px 0px (@grid-unit-size / 5);

	.far, .fas {
		height: (@grid-unit-size / 2);
		line-height: (@grid-unit-size / 2);
		padding-right: (@grid-unit-size / 4);
		text-align: center;
		width: (@grid-unit-size / 2);
	}

	nav-horizontal {
		float: right;
	}
}

.body-subheading {
	color: #A7B1BF;
	font-size: 14px;
	font-weight: 300;
	height: 0;
	line-height: (@grid-unit-size / 3 - 2); // -2px standard offset for fonts with descenders around this font size, keeps it from being too close to the header.
	margin-top: -(@grid-unit-size / 3 - 2);
	position: absolute; // TODO: This is tricky, needs more testing.
	text-transform: uppercase;
}
