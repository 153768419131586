@import '../variables';
//
#modal-view { // see: app.component.html
	background: none no-repeat scroll 0px 0px / cover transparent !important;
	display: block !important;
	height: 0px !important;
	overflow: visible !important;
	position: static !important; // the default value for position, is "static".
	width: 0px !important;
}

#modal {
	background: rgba( 51, 51, 51, 0.6 );
	bottom: 0px;
	display: block;
	height: 100vh;
	left: 0px;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	position: fixed;
	right: 0px;
	text-align: center;
	top: 0px;
	width: 100vw;
	z-index: 100;

	.modal-body {
		background: none no-repeat scroll 0px 0px / cover #FFFFFF;
		display: block;
		position: absolute;
		z-index: 101;
		// no width declared. each modal type should declare it's own.

		@media (min-width: @minWidth-tablet) { // both desktop and tablet.
			margin: 25px auto 25px auto;
			max-height: calc( 100vh - 50px );
			overflow: auto;
		}

		@media (max-width: @minWidth-tablet - 1px) { // mobile phone.
			margin: 20px 20px 20px 20px;
			max-height: calc( 100vh - 40px );
			overflow: auto;
			width: calc( 100% - 40px );
		}

		.content-wrapper { // no height declared
			box-sizing: border-box;
			padding: 50px 50px 50px 50px;
			width: 100%;
		}

		.close-modal {
			color: #000000;
			cursor: pointer;
			height: 32px;
			line-height: 32px;
			position: absolute;
			right: 9px; // calc( (50px - 32px) / 2 );
			top: 9px; // calc( (50px - 32px) / 2 );
			width: 32px;

			&::before, &::after {
				content: "";
				display: inline-block;
				left: 0px;
				position: absolute;
				top: 0px;
			}

			img {
				height: 22px;
				padding: 5px 5px 5px 5px;
				width: 22px;
			}

			&:hover img {
				// filter: drop-shadow( 0px 1px 1px @colorActionPrimaryForeground );
			}
		}

		@media (min-width: @minWidth-tablet) { // both desktop and tablet.
			.content-wrapper {
				.actions {
					.mobile-wrapper {
						&:nth-child(1) { // the next, next to the button.
							padding: 0px 20px 0px 0px;
						}
					}
				}
			}
		}
	}
}

body.modal {
	overflow: hidden;

	#modal {
		position: fixed;
	}
}
