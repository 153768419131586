@import '../variables';
// ===== Text Inputs ===== //
input[type="text"], input[type="password"], input[type="date"], input[type="time"], input[type="tel"], input[type="number"], .input {
	border: 1px solid #CCD2DA;
	border-radius: 3px 3px 3px 3px;
	box-sizing: border-box;
	color: #535F6B;
	font-family: @baseFonts;
	font-size: @fontSize-input;
	height: 60px;
	line-height: 60px;
	padding: 0px calc( 60px / 4 ) 0px calc( 60px / 4 );
	transition: border 0.3s ease-in-out;
	width: 100%;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		margin: 0;
		-moz-appearance: textfield;
		-webkit-appearance: none;
	}

	&:focus {
		border-color: #525f6B;
	}

	&[readonly], &[disabled] {
		color: #CCD2DA;
		// TODO: Lock or other mark.
	}

	&.error, &.ng-invalid.ng-touched {
		border-color: #FF8080;
		box-shadow: 0px 0px 5px #FBC4C0;
	}
}
