@import '../../../../ow-framework/styles/reset';
@import '../../../../ow-framework/styles/variables';
@import '../../../../ow-framework/styles/animations';
@import '../../../../ow-framework/styles/fonts';
@import './fix/fix-layout'; // TODO: move this to the ow-framework, but fix it, first.

// Generic UI, or component things that are not inside component styles.
@import '../../../../ow-framework/styles/common';
@import '../../../../ow-framework/styles/ui/buttons';
@import '../../../../ow-framework/styles/ui/inputs';
@import '../../../../ow-framework/styles/ui/typography';

// Modals
@import '../../../../ow-framework/styles/modals/modals';

// Global overrides - tramples over everything.
html, body {
	color: #252525;
	font-family: 'Poppins', sans-serif;
	// font-family: 'Lato', sans-serif;
	font-weight: 300;
}

.qrcode {
	canvas {
		aspect-ratio: 1;
		height: auto !important;
		max-width: 100%;
		border-radius: 4px;
	}
}
