@import '../variables';
//
.button {
	border-radius: 3px 3px 3px 3px;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	font-size: 18px;
	font-weight: 700;
	height: 50px;
	line-height: 50px;
	outline: 0px none transparent;
	padding: 0px 20px 0px 20px;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;

	&.orange { // this is an examples, create your own.
		background: none no-repeat scroll 0px 0px / cover #FF8000;
		color: #FFFFFF;

		&:active, &:focus, &:link, &:visited { // in-case .button is put onto an anchor tag.
			color: #FFFFFF;
			outline: none;
			text-decoration: none;
		}
	}
}

.ui-button {
	// requires that you set your own margin, padding, height, line-height and width.
	border-radius: 2px 2px 2px 2px;
	box-shadow: 0 2px 0 rgba( 0, 0, 0, 0.015 );
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	height: auto; // set your own
	margin: 0px 0px 0px 0px; // set your own
	outline: 0px none transparent;
	padding: 0px 0px 0px 0px; // set your own
	position: relative;
	text-align: center;
	text-decoration: none;
	touch-action: manipulation;
	transition: all 300ms @ani-fn-fade;
	user-select: none;
	white-space: nowrap;
	width: auto; // set your own

	a {
		color: inherit;
		height: 100%;
		outline: 0px none transparent;
		text-decoration: none;
		width: 100%;
	}

	&.primary {
		&.disabled {
			cursor: default;
			pointer-events: none;
		}
	}

	&.circle {
		border-radius: 50% 50% 50% 50%;
	}

	&.round {
		border-radius: 5000px 5000px 5000px 5000px; // just needs to be larger than the height, and all values needs to be equal.
	}
}
